<template>
  <div class="container px-0">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3 py-2 py-lg-0">
        <div class="shadow-sm border rounded px-3 py-1 h-100">
          <div class="row">
            <div class="col-5 d-flex align-items-center">
              <DoughnutChart
                :items="hostsByLocations"
                description="Locations"
                label="Location"
                :key="`hosts-location-${hosts.length}`"
                style="max-height: 7em"
              />
            </div>
            <div class="col-7 d-flex flex-column text-start small">
              <div class="fs-6 pb-1">
                <FontAwesomeIcon class="me-1" :icon="['fas', 'map-location-dot']" fixed-width />
                <strong>Locations</strong>
              </div>
              <div class="d-flex flex-column flex-grow-1 scrollable">
                <table>
                  <colgroup>
                    <col style="width: 1.5em" />
                  </colgroup>
                  <tr v-for="{ item, count, variant } in hostsByLocations" :key="item">
                    <td><FontAwesomeIcon :class="`text-${variant}`" :icon="['fas', 'circle']" fixed-width /></td>
                    <td class="text-truncate" style="max-width: 0em">
                      <RouterLink
                        :to="{ name: 'Hosts', query: { q: `location=${item}` } }"
                        :title="`${item} (${count})`"
                        class="text-decoration-none"
                        style="max-width: 0em"
                      >
                        {{ item }}
                        <small>({{ count }})</small>
                      </RouterLink>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 py-2 py-lg-0">
        <div class="shadow-sm border rounded px-3 py-1 h-100">
          <div class="row">
            <div class="col-5 d-flex align-items-center">
              <DoughnutChart
                :items="devicesByHosts"
                description="Devices"
                label="Device"
                :key="`hosts-devices-${devices.length}`"
                style="max-height: 7em"
              >
                <div class="mt-auto title">{{ devices.length }}</div>
                <div class="mb-auto">Devices</div>
              </DoughnutChart>
            </div>
            <div class="col-7 d-flex flex-column text-start small">
              <div class="fs-6 pb-1">
                <FontAwesomeIcon class="me-1" :icon="['fas', 'plug']" transform="rotate-45" fixed-width />
                <strong>Devices</strong>
              </div>
              <div class="d-flex flex-column flex-grow-1 scrollable">
                <table>
                  <colgroup>
                    <col style="width: 1.5em" />
                  </colgroup>
                  <tr v-for="{ item, count, variant } in devicesByHosts" :key="item">
                    <td><FontAwesomeIcon :class="`text-${variant}`" :icon="['fas', 'circle']" fixed-width /></td>
                    <td class="text-truncate" style="max-width: 0em">
                      <RouterLink
                        :to="{ name: 'Devices', query: { q: `host=${item}` } }"
                        :title="`${item} (${count})`"
                        class="text-decoration-none"
                        style="max-width: 0em"
                      >
                        {{ item }}
                        <small>({{ count }})</small>
                      </RouterLink>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 py-2 py-lg-0">
        <div class="shadow-sm border rounded px-3 py-1 h-100">
          <div class="d-flex flex-column h-100">
            <h3 class="fs-5">Farm Hosts Status</h3>
            <div class="d-flex justify-content-around align-items-center panel flex-grow-1">
              <div>
                <RouterLink
                  :to="{ name: 'Hosts', query: { q: 'hstatus=healthy' } }"
                  title="Healthy"
                  class="text-success text-decoration-none"
                >
                  <strong class="fs-1 d-block">{{ getHostCountByStatus('healthy') }}</strong>
                  <span class>Healthy</span>
                </RouterLink>
              </div>
              <div>
                <RouterLink
                  :to="{ name: 'Hosts', query: { q: 'hstatus=stale' } }"
                  title="Stale"
                  class="text-warning text-decoration-none"
                >
                  <strong class="fs-1 d-block">{{ getHostCountByStatus('stale') }}</strong>
                  <span class>Stale</span>
                </RouterLink>
              </div>
              <div>
                <RouterLink
                  :to="{ name: 'Hosts', query: { q: 'hstatus=created' } }"
                  title="Created"
                  class="text-secondary text-decoration-none"
                >
                  <strong class="fs-1 d-block">{{ getHostCountByStatus('created') }}</strong>
                  <span class>Created</span>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3 py-2 py-lg-0">
        <div class="shadow-sm border rounded px-3 py-2 h-100">
          <FontAwesomeIcon class="text-muted" :icon="['fas', 'computer']" size="2x" fixed-width />
          <h3 class="fs-5">Go to a farm host</h3>
          <CustomDropdown
            :label="label"
            :items="dropdownHosts"
            icon="angles-down"
            class="border rounded"
            width="100%"
            dropdownwidth="100%"
            @change="(id) => $router.push({ name: 'Hosts.View', params: { id } })"
            text-center
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomDropdown from '@/components/Common/Dropdown.vue';
import DoughnutChart from '@/components/Common/DoughnutChart.vue';

const getObjectsByKey = (object, key, palette) => {
  let index = 0;
  return object.reduce((acc, host) => {
    const itemIndex = acc.map(({ item }) => item).indexOf(host[key]);
    if (itemIndex >= 0) {
      acc[itemIndex].count += 1;
      return acc;
    }
    const variant = palette[index++];
    if (index >= palette.length) index = 0;
    return [...acc, { item: host[key], count: 1, variant }];
  }, []);
};

export default {
  name: 'OverallHostStatus',
  components: { CustomDropdown, DoughnutChart },
  data() {
    return {
      label: 'Select a Farm Host',
    };
  },
  computed: {
    ...mapGetters(['hosts', 'devices']),
    dropdownHosts() {
      return this.hosts.map(({ host_id }) => ({ key: host_id, value: host_id }));
    },
    hostsByLocations() {
      const palette = ['success', 'info', 'warning', 'danger', 'primary', 'secondary', 'light', 'dark'];
      return getObjectsByKey(this.hosts, 'location', palette);
    },
    devicesByHosts() {
      const palette = ['success', 'info', 'warning', 'danger', 'primary', 'secondary', 'light', 'dark'];
      return getObjectsByKey(this.devices, 'host_id', palette);
    },
  },
  methods: {
    getHostCountByStatus(status) {
      const hosts = this.hosts.filter(({ health_status }) => health_status === status);
      return hosts.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  line-height: 1;
}

.scrollable {
  max-height: 5em;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.25em;
  }

  &::-webkit-scrollbar-track {
    background: var(--bs-gray-300);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--bs-gray-600);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--bs-gray-900);
  }
}
</style>
