const endpoint = '/tests';

/**
 * Recursively fetch log events until there is no more events.
 *
 * @param { CloudwatchLogs } logs
 * @param { Object         } params
 */
const fetchLogEvents = async (logs, params) => {
  const data = await new Promise((resolve, reject) => {
    logs.getLogEvents(params, (error, data) => {
      if (error) return reject(error);
      return resolve(data);
    });
  });

  const { events, nextForwardToken: nextToken } = data;

  if (!events.length) return [];

  const nextEvents = await fetchLogEvents(logs, { ...params, nextToken });

  return [...events, ...nextEvents];
};

export default {
  state: () => {
    return { tests: [], fetching: false, nextToken: null };
  },
  getters: {
    tests(state) {
      return state.tests;
    },
    isFetchingTests(state) {
      return state.fetching;
    },
  },
  mutations: {
    clearTests(store) {
      store.tests = [];
    },
    setTests(store, tests) {
      const updated = store.tests.map((oldTest) => {
        const newTest = tests.find(({ test_id }) => test_id === oldTest.test_id);
        if (newTest) return Object.assign({}, oldTest, newTest);
        return oldTest;
      });

      store.tests = [...tests.filter((test) => !store.tests.find(({ test_id }) => test_id === test.test_id)), ...updated];
    },
    updateTest(store, test) {
      const [updated] = store.tests.filter(({ test_id }) => test_id === test.test_id);
      if (updated) Object.assign(updated, test);
      else store.tests.push(test);
    },
  },
  actions: {
    async fetchTests({ state, commit, getters: { request, groupFilter } }, nextToken) {
      const count = 50;

      state.fetching = true;

      const params = { count };

      if (groupFilter !== 'All Groups') params['group_id'] = groupFilter;

      if (nextToken) params['next_token'] = nextToken;

      const { data } = await request.get(endpoint, { params }).catch(() => ({ data: { tests: [] } }));

      state.fetching = false;
      state.nextToken = data.next_token;
      commit('setTests', data.tests);
    },
    async fetchTest({ commit, getters: { request, groupFilter } }, id) {
      const { data } = await request.get(`${endpoint}/${id}`);
      if (['All Groups', data.test.group_id].includes(groupFilter)) commit('updateTest', data.test);
      return data.test;
    },
    async fetchTestDevices({ getters: { request } }, id) {
      const devices = await request.get(`${endpoint}/${id}/devices`).then(async ({ data: { devices } }) => {
        if (devices.length) return devices;
        const { data } = await request.get(`${endpoint}/${id}/devices`, { params: { fields: 'device_id' } });
        return data.devices.map((device) => ({ device_id: device, communication_interface: { interface_type: '?' } }));
      });

      return devices;
    },
    async fetchTestReport({ getters: { logs } }, id) {
      const params = { logGroupName: 'test_instances', logStreamName: id, startFromHead: true, limit: 10000 };
      return fetchLogEvents(logs, params);
    },
    async fetchTestXmlFile({ getters: { configs, s3 } }, id) {
      const { TEST_JOBS_BUCKET: Bucket } = configs;
      return new Promise((resolve, reject) => {
        const params = { Bucket, Key: `${id}/report.xml` };
        s3.getObject(params, (error, data) => {
          if (error) return reject(error);
          return resolve(data.Body);
        });
      });
    },
    async fetchTestHtmlUrls({ getters: { configs, s3 } }, id) {
      const { TEST_JOBS_BUCKET: Bucket } = configs;
      return new Promise((resolve, reject) => {
        const params = { Bucket, Prefix: id };
        s3.listObjectsV2(params, (error, data) => {
          if (error) return reject(error);
          const reportUrls = data.Contents.reduce((acc, { Key }) => {
            if (!/report\d*\.html$/i.test(Key)) return acc;
            return [...acc, Key];
          }, []);

          if (!reportUrls.length) return reject(new Error('No report URLs available'));

          const sortedReportUrls = reportUrls.sort((a, b) => {
            const numA = parseInt(a.match(/report(?<count>\d+)\.html$/)?.groups.count);
            const numB = parseInt(b.match(/report(?<count>\d+)\.html$/)?.groups.count);
            return numA < numB ? -1 : 1;
          });

          return resolve(sortedReportUrls);
        });
      });
    },
    async fetchTestHtmlFile({ getters: { configs, s3 } }, url) {
      const { TEST_JOBS_BUCKET: Bucket } = configs;
      return new Promise((resolve, reject) => {
        const params = { Bucket, Key: url };
        s3.getObject(params, (error, data) => {
          if (error) return reject(error);
          const html = data.Body.reduce((acc, char) => acc + String.fromCharCode(char), '');
          return resolve(html);
        });
      });
    },
  },
};
