<template>
  <footer v-if="$store.getters.user">
    <div class="container-fluid text-start">
      <ul class="nav">
        <li class="nav-item me-3">
          <a href="https://w.amazon.com/bin/view/IHM/MVP/Program/TAP/" target="_blank" class="nav-link p-0">
            <FontAwesomeIcon :icon="['fas', 'info-circle']" />
            About TAP
          </a>
        </li>
        <li class="nav-item me-3">
          <a href="mailto:ihm-osplat-tap@amazon.com?subject=Dashboard Inquiry" target="_blank" class="nav-link p-0">
            <FontAwesomeIcon :icon="['fas', 'at']" />
            Contact TAP Team
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'LayoutFooter',
};
</script>

<style lang="scss" scoped></style>
