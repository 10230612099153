<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="text-center">
      <h1 class="m-0">{{ appName }}</h1>
      <div class="py-2">
        <div class="text-danger">{{ error }}</div>
        <div class="font-monospace">{{ description }}</div>
      </div>
      <FontAwesomeIcon :icon="['fas', 'handshake-slash']" size="3x" class="fa-fade" fixed-width />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SplashView',
  computed: {
    ...mapGetters(['configs']),
    appName() {
      const { APP_NAME } = this.configs;
      return APP_NAME;
    },
    error() {
      return this.$route.query.error?.toUpperCase();
    },
    description() {
      return this.$route.query.error_description;
    },
  },
};
</script>

<style lang="scss" scoped></style>
