import axios from 'axios';
import { CognitoIdentityCredentials } from 'aws-sdk';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

/**
 * Get authorization token from Cognito OAuth2 provider. If the token is found, calls CognitoUser to handle
 * the session storage flow. Returns CognitoUser object.
 *
 * @param   { String } code
 * @param   { String } baseURL
 * @param   { String } redirectUri
 * @param   { String } clientId
 * @param   { Object } pool
 * @returns { Object }
 */
const oauthFlow = async (code, baseURL, redirectUri, clientId, pool) => {
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  const searchParams = { code, redirect_uri: redirectUri, client_id: clientId, grant_type: 'authorization_code' };
  const { data } = await axios.post('/oauth2/token', new URLSearchParams(searchParams).toString(), { baseURL, headers });
  const accessToken = new CognitoAccessToken({ AccessToken: data.access_token });
  const idToken = new CognitoIdToken({ IdToken: data.id_token });
  const refreshToken = new CognitoRefreshToken({ RefreshToken: data.refresh_token });
  const userParams = { IdToken: idToken, AccessToken: accessToken, RefreshToken: refreshToken };
  const cognitoSession = new CognitoUserSession(userParams);
  const payload = idToken.decodePayload();
  const [{ userId }] = payload.identities;
  const user = new CognitoUser({ Username: userId, Pool: pool });
  user.setSignInUserSession(cognitoSession);
  return cognitoSession;
};

/**
 * Get cached authorization token. Calls CognitoUser to handle the session storage flow. Returns CognitoUser object.
 *
 * @param   { String } clientId
 * @param   { Object } pool
 * @returns { Object }
 */
const localFlow = async (clientId, pool) => {
  const userId = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);
  const user = new CognitoUser({ Username: userId, Pool: pool });
  return new Promise((resolve, reject) => {
    return user.getSession((error, session) => {
      if (error) return reject(error);
      return session.isValid() ? resolve(session) : reject(new Error('Session has expired'));
    });
  });
};

/**
 * Returns true if credentials are expired. Credentials are considered expired if they are missing, the expireTime has elapsed,
 * or accessKeyId, secretAccessKey, and sessionToken are missing from the credential object. To make sure that we have enough time
 * to use our credential, we consider any credential that should expire in 5 minutes as expired.
 *
 * @param   { Object  } credentials
 * @returns { Boolean }
 */
const haveCredentialsExpired = (credentials) => {
  if (!credentials) return true;

  const { expireTime, accessKeyId, secretAccessKey, sessionToken } = credentials;

  if (!expireTime || !accessKeyId || !secretAccessKey || !sessionToken) return true;

  const minutesRemaining = Math.floor((new Date(expireTime) - new Date()) / 60000);

  return minutesRemaining < 5;
};

/**
 * Authorization strategy. Should get authorization token from oauth provider if code  exists. Else, checks
 * from the local storage.
 *
 * @param   { string } origin
 * @param   { string } code
 * @param   { Object } getters
 * @returns { Object }
 */
const authflow = async (origin, code, getters) => {
  const { credentials, configs } = getters;
  const { COGNITO_URL, USER_POOL_ID, CLIENT_ID, IDENTITY_POOL_ID, REGION } = { ...configs };

  const pool = new CognitoUserPool({ UserPoolId: USER_POOL_ID, ClientId: CLIENT_ID });

  const session = await (() => {
    if (code) return oauthFlow(code, COGNITO_URL, origin, CLIENT_ID, pool);
    return localFlow(CLIENT_ID, pool);
  })();

  const [{ userId }] = session.idToken.payload.identities;

  if (!haveCredentialsExpired(credentials)) return { userId, ...credentials };

  const Logins = { [`cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`]: session.idToken.jwtToken };

  const awsCredentials = new CognitoIdentityCredentials({ IdentityPoolId: IDENTITY_POOL_ID, Logins }, { region: REGION });

  await awsCredentials.getPromise();

  const { accessKeyId, expireTime, secretAccessKey, sessionToken } = awsCredentials;

  return { userId, accessKeyId, expireTime, secretAccessKey, sessionToken };
};

export default {
  state: () => {
    return { user: null, credentials: null };
  },
  getters: {
    user(state) {
      return state.user;
    },
    credentials(state) {
      return state.credentials;
    },
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    credentials(state, credentials) {
      state.credentials = credentials;
    },
  },
  actions: {
    async authorize({ commit, getters }, payload) {
      const { origin, code } = payload;
      const { userId, ...credentials } = await authflow(origin, code, getters);
      const photo = `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userId}`;
      const user = { userId, photo };
      commit('user', user);
      commit('credentials', credentials);
      return user;
    },
  },
};
