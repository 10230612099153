/**
 * Construct the cloudwatch Metric parameter.
 *
 * @param   { Date        } startTime
 * @param   { Date        } endTime
 * @param   { Set[String] } dimensions
 * @returns { Object      }
 */
const getParams = (startTime, endTime, dimensions) => {
  const Namespace = 'TAP';
  const Period = 86400;
  const metricNames = ['SuccessCount', 'FailureCount', 'ErrorCount'];
  const metricDataQueries = [...dimensions].flatMap((dimension) => {
    const Dimensions = [{ Name: 'ProductId', Value: dimension }];
    return metricNames.map((MetricName) => {
      const Id = `m${MetricName}${dimension}`.replace(/\s/g, '_');
      return { Id, MetricStat: { Metric: { Namespace, MetricName, Dimensions }, Period, Stat: 'Sum' } };
    });
  });
  return { StartTime: startTime, EndTime: endTime, MetricDataQueries: metricDataQueries };
};

export default {
  state: () => {
    return { dashboardMetrics: [] };
  },
  getters: {
    dashboardMetrics(state) {
      return state.dashboardMetrics;
    },
  },
  mutations: {
    updateDashboardMetrics(store, metrics) {
      store.dashboardMetrics = metrics;
    },
  },
  actions: {
    async fetchDashboardMetrics({ commit, getters: { cloudwatch } }, period) {
      const [startTime, endTime] = period || [];

      if (!startTime || !endTime) return commit('updateDashboardMetrics', []);

      const metrics = await new Promise((resolve, reject) => {
        cloudwatch.listMetrics({ Namespace: 'TAP' }, (error, data) => {
          if (error) return reject(error);
          const dimensions = data.Metrics.reduce((set, { Dimensions, MetricName }) => {
            if (['FailureCount', 'ErrorCount', 'SuccessCount'].includes(MetricName)) {
              Dimensions.forEach(({ Value }) => set.add(Value));
            }
            return set;
          }, new Set());

          if (!dimensions.size) return reject(new Error('No MetricDataQueries found'));

          const params = getParams(startTime, endTime, dimensions);

          cloudwatch.getMetricData(params, (error, response) => {
            if (error) return reject(error);
            return resolve(response.MetricDataResults);
          });
        });
      });
      commit('updateDashboardMetrics', metrics);
    },
  },
};
