<template>
  <div id="container" class="container-fluid">
    <div class="container py-3">
      <OverallHostStatus />
    </div>
    <div class="container py-4">
      <div class="row">
        <div class="col-6 flex-grow-1"><TestRun /></div>
        <div class="col-6 flex-grow-1"><DeviceStatus /></div>
      </div>
    </div>
    <div class="container py-3">
      <AtAGlanceMetric />
    </div>
  </div>
</template>

<script>
import OverallHostStatus from '@/components/OverallHostStatus.vue';
import TestRun from '@/components/TestRun.vue';
import DeviceStatus from '@/components/DeviceStatus.vue';
import AtAGlanceMetric from '@/components/AtAGlanceMetric.vue';

export default {
  name: 'DashboardView',
  components: { OverallHostStatus, TestRun, DeviceStatus, AtAGlanceMetric },
};
</script>

<style lang="scss" scoped>
#container {
  min-height: 30em;
}
</style>
