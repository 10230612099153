<template>
  <div
    class="position-relative text-start"
    :class="{ 'text-end': textEnd, 'text-center': textCenter }"
    v-click-outside="close"
  >
    <button
      class="btn btn-sm border-0 text-start d-flex align-items-center"
      :class="{ 'text-end': textEnd, 'text-center': textCenter }"
      @click="toggle"
      :style="{ minWidth: width }"
    >
      <FontAwesomeIcon v-if="icon" class="text-muted me-1" :icon="['fas', icon]" fixed-width />
      <span class="flex-grow-1 text-truncate" style="width: 0em">{{ label }}</span>
    </button>
    <div v-if="show" class="position-absolute wrapper shadow-sm border bg-white py-1" :style="{ width: dropdownwidth }">
      <div
        v-for="item in items"
        :key="item.key"
        :title="item.value"
        class="py-1 px-3 item text-truncate"
        :class="{ 'bg-light': item.key === label && highlightClass }"
        @click="triggerChange(item.key)"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomDropdown',
  props: {
    label: { type: String, required: true },
    icon: { type: String, required: false, default: null },
    width: { type: String, required: false, default: 'fit-content' },
    dropdownwidth: { type: String, required: false, default: 'fit-content' },
    textEnd: { type: Boolean, required: false, default: false },
    textCenter: { type: Boolean, required: false, default: false },
    highlightClass: { type: String, required: false, default: null },
    items: {
      type: Array,
      required: false,
      default: () => [],
      validator: (values) => {
        const props = ['key', 'value'];
        return values.every((value) => Object.keys(value).every((key) => props.includes(key)));
      },
    },
  },
  data() {
    return { show: false };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    close() {
      this.show = false;
    },
    triggerChange(key) {
      this.show = false;
      this.$emit('change', key);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-width: 13rem;
  z-index: 100;

  .item {
    cursor: pointer;

    &:hover {
      background-color: var(--bs-light);
    }
  }
}
</style>
