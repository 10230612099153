import Vue from 'vue';
import Vuex from 'vuex';
import Helper from '@/store/helper';
import Auth from '@/store/auth';
import Host from '@/store/host';
import Group from '@/store/group';
import Device from '@/store/device';
import Test from '@/store/test';
import Metrics from '@/store/metrics';

Vue.use(Vuex);

export default new Vuex.Store({ modules: { Helper, Auth, Host, Group, Device, Test, Metrics } });
