<template>
  <div v-if="items.length" class="container-fluid text-start fixed-bottom me-0">
    <div class="row">
      <div class="col-auto ms-auto p-0 errors">
        <div class="cursor-pointer p-1 d-flex align-items-center justify-content-between" @click="toggleDisplay">
          <h1 class="fs-6 fw-bold m-0 me-1">{{ items.length }} Error{{ items.length > 1 ? 's' : '' }} Found</h1>
          <FontAwesomeIcon :icon="['fas', display ? 'angles-down' : 'angles-up']" fixed-width />
        </div>
        <div v-if="display" class="wrapper border-top border-light small p-1" ref="wrapper">
          <div
            v-for="{ timestamp, ...error } in items"
            :key="timestamp"
            class="my-2 ps-2 border-start border-danger border-4"
            role="alert"
          >
            <small class="d-block text-primary">{{ new Date(timestamp) }}</small>
            <span>{{ error }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ErrorLog',
  data() {
    return { display: false };
  },
  computed: {
    ...mapGetters(['errors']),
    items() {
      const errors = this.errors.map((error) => ({ ...error, archived: undefined }));
      return errors.sort((a, b) => b.timestamp - a.timestamp);
    },
  },
  methods: {
    toggleDisplay() {
      this.display = !this.display;
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed-bottom {
  width: fit-content;
  max-width: 100%;

  .errors {
    background-color: #f8d7da;
    color: #842029;
  }

  .wrapper {
    max-height: 75vh;
    overflow-y: auto;
  }
}
</style>
