<template>
  <svg width="100%" height="100%" viewBox="0 0 42 42">
    <title v-if="description">{{ description }}</title>
    <circle cx="21" cy="21" :r="radius" :fill="backgroundFill"></circle>
    <circle cx="21" cy="21" :r="radius" fill="transparent" :stroke="baseFill" stroke-width="3"></circle>

    <circle
      cx="21"
      cy="21"
      :r="radius"
      fill="transparent"
      :stroke="getColor(variant)"
      stroke-width="3"
      :stroke-dasharray="getDashArray(index)"
      :stroke-dashoffset="getDashOffset(index)"
      v-for="({ item, variant }, index) in items"
      :key="item"
    >
      <title>{{ item }}</title>
    </circle>

    <foreignObject x="10" y="10" width="21" height="21">
      <div class="d-flex flex-column w-100 h-100 justify-content-center align-items-center label bg-transparent">
        <slot>
          <div class="mt-auto title" :class="{ 'mb-auto': !label }">{{ items.length }}</div>
          <div class="mb-auto" v-if="label">{{ label }}{{ items.length > 1 ? 's' : '' }}</div>
        </slot>
      </div>
    </foreignObject>
  </svg>
</template>

<script>
export default {
  name: 'DoughnutChart',
  props: {
    items: {
      type: Array,
      required: true,
      validator: (values) => {
        const props = ['item', 'count', 'variant'];
        return values.every((value) => Object.keys(value).every((key) => props.includes(key)));
      },
    },
    variants: {
      type: Object,
      required: false,
      default: () => ({}),
      validator: (values) => values && typeof values === 'object' && !Array.isArray(values),
    },
    description: { type: String, required: false },
    label: { type: String, required: false },
    background: { type: String, required: false },
    base: { type: String, required: false },
  },
  created() {},
  data() {
    const style = getComputedStyle(document.body);
    return {
      style,
      radius: 100 / (2 * Math.PI),
    };
  },
  computed: {
    componentVariants() {
      return Object.freeze({
        primary: this.style.getPropertyValue('--bs-primary'),
        secondary: this.style.getPropertyValue('--bs-secondary'),
        success: this.style.getPropertyValue('--bs-success'),
        info: this.style.getPropertyValue('--bs-info'),
        warning: this.style.getPropertyValue('--bs-warning'),
        danger: this.style.getPropertyValue('--bs-danger'),
        light: this.style.getPropertyValue('--bs-light'),
        dark: this.style.getPropertyValue('--bs-dark'),
        ...this.variants,
      });
    },
    backgroundFill() {
      return this.componentVariants[this.background] || this.componentVariants.light;
    },
    baseFill() {
      return this.componentVariants[this.base] || this.componentVariants.primary;
    },
    itemCount() {
      return this.items.reduce((sum, { count }) => sum + count, 0);
    },
  },
  methods: {
    getColor(variant) {
      return this.componentVariants[variant] || this.componentVariants.primary;
    },
    getDashArray(index) {
      const percentage = Math.round(100 * (this.items[index]?.count / this.itemCount));
      const remaining = 100 - percentage;
      return `${percentage} ${remaining}`;
    },
    getDashOffset(index) {
      return this.items.reduce((sum, _, itemIndex) => {
        if (index < itemIndex) return sum;
        const [, lag = 25] = this.getDashArray(itemIndex - 1).split(/\D+/);
        return (sum + parseInt(lag || 25, 10)) % 100;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 0.25rem;
  line-height: 1;

  .title {
    font-size: 0.65rem;
    font-weight: bold;
  }
}
</style>
