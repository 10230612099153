import Vue from 'vue';

String.prototype.toTitleCase = function (snakeToTitle = false) {
  let result = this.replace(/([A-Z]+)/g, ' $1');
  if (snakeToTitle) {
    result = result.replace(/_./g, (match) => ` ${match.charAt(1).toUpperCase()}`);
  }
  return result.charAt(0).toUpperCase() + result.slice(1);
};

Vue.filter('toTitleCase', (value) => {
  if (!value) return '';
  return value.toTitleCase();
});

Vue.filter('simpleDate', (value) => {
  if (!value) return '-';
  const date = new Date(value * 1000);
  const isoDate = date.toISOString();
  return isoDate.replace(/\.\d{3}Z$/, '').replace('T', ' @');
});

Vue.filter('getDate', (value) => {
  const date = new Date(value * 1000);
  if (!date.getTime()) return '-';
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
});

Vue.filter('getTime', (value) => {
  const date = new Date(value * 1000);
  if (!date.getTime()) return '-';
  const datetime = date.toLocaleDateString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
  return datetime.toLowerCase().replace(/.*,\s/, '');
});

Vue.filter('stripDash', (value) => {
  if (!value) return '';
  return value.replace(/^-+|-+$/g, '');
});
