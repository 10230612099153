const endpoint = '/groups';

export default {
  state: () => {
    const groupFilter = localStorage.getItem('GroupFilter') || 'All Groups';
    return { groups: [], fetching: false, groupFilter };
  },
  getters: {
    groups(state) {
      return state.groups;
    },
    groupFilter(state) {
      return state.groupFilter;
    },
    isFetchingGroups(state) {
      return state.fetching;
    },
  },
  mutations: {
    setGroups(store, groups) {
      store.groups = groups;
    },
    setGroupFilter(store, groupFilter) {
      store.groupFilter = groupFilter;
      localStorage.setItem('GroupFilter', groupFilter);
    },
  },
  actions: {
    async fetchGroups({ state, commit, getters: { request } }) {
      state.fetching = true;
      const { data } = await request.get(endpoint).catch(() => ({ data: { groups: [] } }));
      state.fetching = false;
      commit('setGroups', data.groups);
    },
    async getGroup({ getters: { request } }, id) {
      const { data } = await request.get(`${endpoint}/${id}`);
      return data.group;
    },
  },
};
