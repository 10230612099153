<template>
  <div class="container-fluid position-relative d-flex flex-column shadow-sm py-2 h-100">
    <div class="position-absolute custom-tooltip px-2">
      <FontAwesomeIcon
        @click="tooltip = !tooltip"
        :icon="['fas', tooltip ? 'times' : 'info-circle']"
        class="cursor-pointer"
        :class="tooltip ? 'text-danger' : 'text-info'"
        fixed-width
      />
    </div>
    <h3 class="fs-5">
      <span>Test Run</span>
      <small class="ps-1">[{{ period | title }}]</small>
      <small class="ps-1"><small>- all groups</small></small>
    </h3>
    <div v-if="tooltip" class="py-2 text-start">
      <table class="table table-sm table-borderless my-0">
        <tbody>
          <tr>
            <th>Ended</th>
            <td>The overall count of tests that have been completed or failed.</td>
          </tr>
          <tr>
            <th>Successful</th>
            <td>Tests that were successfully completed.</td>
          </tr>
          <tr>
            <th>Failed/Errors</th>
            <td>Tests that were failed (error) or completed with failure.</td>
          </tr>
          <tr>
            <th>Failed (Product ID)</th>
            <td>
              Tests that were failed (error) or completed with failure by Product ID. The summation should be equal to
              Failed/Errors
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-else>
      <div class="py-5" v-if="loading">
        <FontAwesomeIcon :icon="['fas', 'compass']" size="2x" class="text-muted" spin />
        <div>Loading...</div>
      </div>
      <template v-else>
        <div class="row panel">
          <div class="col-4 text-primary">
            <RouterLink
              class="d-inline-block text-decoration-none text-reset"
              :to="{ name: 'Tests', query: { q: 'completed or failed' } }"
            >
              <strong class="fs-1 d-block">{{ endedTests }}</strong>
              <span class="fs-6">Ended</span>
            </RouterLink>
          </div>
          <div class="col-4 text-success">
            <RouterLink
              class="d-inline-block text-decoration-none text-reset"
              :to="{ name: 'Tests', query: { q: 'result=pass' } }"
            >
              <strong class="fs-1 d-block">{{ successCount }}</strong>
              <span class="fs-6">Successful</span>
            </RouterLink>
          </div>
          <div class="col-4 text-danger">
            <RouterLink
              class="d-inline-block text-decoration-none text-reset"
              :to="{ name: 'Tests', query: { q: 'result=fail' } }"
            >
              <strong class="fs-1 d-block">{{ failureCount }}</strong>
              <span class="fs-6">Failed/Errors</span>
            </RouterLink>
          </div>
          <div class="col-12"><hr class="border-1" /></div>
        </div>
        <div class="d-flex justify-content-around panel">
          <RouterLink
            class="d-inline-block text-decoration-none"
            v-for="(metric, key) in dashboardMetricsByProductId"
            :title="`Failed & Errors on ${key}`"
            :key="key"
            :to="{ name: 'Tests', query: { q: `${key} and (state=failed or result=fail)` } }"
          >
            <div class="text-danger">
              <strong class="fs-1">{{ metric.failure + metric.error }}</strong>
              <small>Failed</small>
            </div>
            <strong class="fs-6 d-block text-truncate">{{ key }}</strong>
          </RouterLink>
        </div>
      </template>
      <div ref="pagination" class="d-flex justify-content-center align-items-center mt-auto pt-2">
        <div
          @click="updateTestRun(null)"
          class="cursor-pointer mx-1 rounded px-1 border small"
          :class="days ? 'bg-light border-secondary' : 'border-white'"
        >
          1D
        </div>
        <div
          @click="updateTestRun('weeks')"
          class="cursor-pointer mx-1 rounded px-1 border small"
          :class="weeks ? 'bg-light border-secondary' : 'border-white'"
        >
          1W
        </div>
        <div
          @click="updateTestRun('months')"
          class="cursor-pointer mx-1 rounded px-1 border small"
          :class="months ? 'bg-light border-secondary' : 'border-white'"
        >
          1M
        </div>
        <div
          @click="updateTestRun('years')"
          class="cursor-pointer mx-1 rounded px-1 border small"
          :class="years ? 'bg-light border-secondary' : 'border-white'"
        >
          1Y
        </div>
        <div
          @click="updateTestRun('max')"
          class="cursor-pointer mx-1 rounded px-1 border small"
          :class="max ? 'bg-light border-secondary' : 'border-white'"
        >
          Max
        </div>
        <div
          @click="updateTestRun('custom')"
          class="cursor-pointer mx-1 rounded px-1 border small"
          :class="custom ? 'bg-light border-secondary ' : 'border-white'"
        >
          Custom
        </div>
      </div>
      <div v-if="custom" ref="custom" class="d-flex justify-content-center align-items-center pt-2 small">
        <div class="px-2">
          <div>From</div>
          <div class="input-group input-group-sm">
            <input type="date" class="form-control" v-model="fromDate" :min="fromMinDate" :max="fromMaxDate" />
          </div>
        </div>
        <div class="px-2">
          <div>To</div>
          <div class="input-group input-group-sm">
            <input type="date" class="form-control" v-model="toDate" :min="toMinDate" :max="toMaxDate" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
const INITIAL_DATE = '2023-01-01';

const sortFn = ({ Id: a }, { Id: b }) => {
  const p1 = a.replace(/^m.*?Count/, '');
  const p2 = b.replace(/^m.*?Count/, '');
  return p1 < p2 ? -1 : 1;
};

const getTimestamp = (period, fromDate, toDate) => {
  const today = new Date();
  const startTime = new Date(today);
  if (period === 'custom') {
    if (!fromDate && !toDate) return [null, null];
    const fromDateTime = fromDate ? new Date(fromDate) : new Date(INITIAL_DATE);
    const toDateTime = toDate ? new Date(toDate) : new Date();
    toDateTime.setUTCHours(23, 59, 59, 999);
    return [fromDateTime, toDateTime];
  }
  if (period === 'max') {
    return [new Date(INITIAL_DATE), today];
  }
  if (period === 'years') {
    startTime.setUTCFullYear(startTime.getUTCFullYear() - 1);
    return [startTime, today];
  }
  if (period === 'months') {
    startTime.setUTCMonth(startTime.getUTCMonth() - 1);
    return [startTime, today];
  }
  if (period === 'weeks') {
    startTime.setUTCDate(startTime.getUTCDate() - 7);
    return [startTime, today];
  }
  startTime.setUTCDate(startTime.getUTCDate() - 1);
  return [startTime, today];
};

export default {
  name: 'TestRun',
  mounted() {
    this.updateTestRun(null, null, null, true);
  },
  watch: {
    fromDate(value) {
      this.updateTestRun('custom', value, this.toDate, true);
    },
    toDate(value) {
      this.updateTestRun('custom', this.fromDate, value, true);
    },
  },
  data() {
    return { tooltip: false, period: null, fromDate: null, toDate: null, loading: false };
  },
  computed: {
    ...mapGetters(['dashboardMetrics']),
    days() {
      return !this.weeks && !this.months && !this.years && !this.max && !this.custom;
    },
    weeks() {
      return this.period === 'weeks';
    },
    months() {
      return this.period === 'months';
    },
    years() {
      return this.period === 'years';
    },
    max() {
      return this.period === 'max';
    },
    custom() {
      return this.period === 'custom';
    },
    fromMinDate() {
      return new Date(INITIAL_DATE).toISOString().split('T')[0];
    },
    fromMaxDate() {
      return new Date(this.toDate || new Date()).toISOString().split('T')[0];
    },
    toMinDate() {
      return new Date(this.fromDate || INITIAL_DATE).toISOString().split('T')[0];
    },
    toMaxDate() {
      return new Date().toISOString().split('T')[0];
    },
    endedTests() {
      return this.dashboardMetrics.reduce((sum, { Values }) => {
        const ended = Values.reduce((acc, value) => acc + value, 0);
        return sum + ended;
      }, 0);
    },
    successCount() {
      return this.dashboardMetrics.reduce((sum, { Id, Values }) => {
        if (!/SuccessCount/.test(Id)) return sum;
        const ended = Values.reduce((acc, value) => acc + value, 0);
        return sum + ended;
      }, 0);
    },
    failureCount() {
      return this.dashboardMetrics.reduce((sum, { Id, Values }) => {
        if (!/(Failure|Error)Count/.test(Id)) return sum;
        const ended = Values.reduce((acc, value) => acc + value, 0);
        return sum + ended;
      }, 0);
    },
    dashboardMetricsByProductId() {
      const metrics = this.dashboardMetrics.map((test) => test).sort(sortFn);
      return metrics.reduce((acc, { Id, Values }) => {
        const { groups } = Id.match(/m(?<name>.*?Count)(?<productId>.*)/);
        groups.productId = groups.productId.replace(/_/g, ' ');
        if (!acc[groups.productId]) acc[groups.productId] = { successful: 0, failure: 0, error: 0 };
        const count = Values.reduce((acc, value) => acc + value, 0);
        if (groups.name === 'SuccessCount') acc[groups.productId].successful += count;
        else if (groups.name === 'FailureCount') acc[groups.productId].failure += count;
        else if (groups.name === 'ErrorCount') acc[groups.productId].error += count;
        return acc;
      }, {});
    },
  },
  methods: {
    ...mapActions(['fetchDashboardMetrics']),
    getPeriod(period) {
      return ['custom', 'max', 'years', 'months', 'weeks'].includes(period) ? period : null;
    },
    async updateTestRun(period, fromDate = null, toDate = null, force = false) {
      const normalizedPeriod = this.getPeriod(period);
      if (!force && normalizedPeriod === this.period) return;
      this.period = normalizedPeriod;
      this.loading = true;
      await this.fetchDashboardMetrics(getTimestamp(this.period, fromDate, toDate)).catch((error) => {
        this.$store.commit('notifications', {
          level: 'danger',
          title: "Can't fetch Test Run Metrics",
          message: error.message,
        });
      });
      this.loading = false;
    },
    completed(test) {
      return ['completed', 'failed'].includes(test.state);
    },
  },
  filters: {
    title(value) {
      if (value === 'weeks') return 'Recent week';
      if (value === 'months') return 'Recent month';
      if (value === 'years') return 'Recent year';
      if (value === 'max') return 'All';
      if (value === 'custom') return 'Custom';
      return 'Recent day';
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  small {
    font-size: 75%;
  }
}
.custom-tooltip {
  right: 0em;

  .custom-tooltip-popup {
    width: 50%;
  }
}
.panel {
  line-height: 1;
}
</style>
