import Vue from 'vue';

const isCondition = (value) => ['and', 'or'].includes(value);

const isOpeningParenthesis = (value) => value === '(';

const isClosingParenthesis = (value) => value === ')';

const isSearchKeyword = (value) => !['and', 'or', '(', ')'].includes(value);

/**
 * The function automatically adds "and" if two consecutive search keys have no joining condition.
 *
 * @param   { String [] } infix
 * @returns { String [] }
 */
const fixQuery = (infix) => {
  if (!infix.length) return [];

  const [current, ...next] = infix;

  if (isSearchKeyword(current) && next[0] && (isSearchKeyword(next[0]) || isOpeningParenthesis(next[0]))) {
    next.splice(0, 0, 'and');
  }

  if (isClosingParenthesis(current) && next[0] && !isClosingParenthesis(next[0]) && !isCondition(next[0])) {
    next.splice(0, 0, 'and');
  }

  return [current, ...fixQuery(next)];
};

/**
 * Translate the infix input notation to postfix for easier searching.
 * Customized Shunting Yard Algorithm.
 *
 * @param   { String [] } infix
 * @returns { String [] }
 */
const infixToPostfix = (infix) => {
  const stack = [];
  const postfix = [];

  let operands = 0;
  let operators = 0;

  for (const current of infix) {
    if (isSearchKeyword(current)) {
      operands++;
      postfix.push(current);
    } else if (isCondition(current)) {
      while (stack.length && isCondition(stack[stack.length - 1])) {
        operators++;
        postfix.push(stack.pop());
      }
      stack.push(current);
    } else if (isOpeningParenthesis(current)) {
      stack.push(current);
    } else if (isClosingParenthesis(current)) {
      while (stack.length && !isOpeningParenthesis(stack[stack.length - 1])) {
        postfix.push(stack.pop());
      }
      stack.pop();
    }
  }

  while (stack.length) {
    const current = stack.pop();
    if (isOpeningParenthesis(current)) continue;

    if (isCondition(current)) operators++;

    if (operators < operands) postfix.push(current);
  }

  return postfix;
};

/**
 * Custom search filter algorithm. The algorithm takes tokens expressed in RPN and performs a set of operation as follow:
 *   1. Creates an empty stack to store results
 *   2. Loops through tokens and check if a token is an operator
 *     a. If token is an operator, pop twice the stack, evaluate the operation, and push back the result into the stack
 *     b. Else, check if the token is has sub-operation =, !=, <, <=, >, and >=
 *         i. If the token has a suboperation, evaluate the suboperation. Perform a strict operation
 *         ii. Else, match the token to the attribute
 *   3. Return the top of the stack.
 *
 * @param   { Object [] } headers
 * @param   { Object [] } item
 * @param   { String [] } tokens
 * @returns { Boolean   }
 */
const evaluate = (headers, item, tokens) => {
  const stack = [];

  for (let index = 0; index < tokens.length; index++) {
    const token = tokens[index];
    if (!isCondition(token)) {
      const exists = (() => {
        // Match something like a = b, a >= b, etc.
        const match = token.match(/(?<property>[^!<=>]+)\s*(?<operator>!?=|<=?|>=?)\s*(?<value>[^!<=>]+)?/);
        if (!match) {
          return Object.entries(item).some(([property, value]) => {
            const header = headers.find(({ key }) => property === key);
            if (!header) return false;
            const regex = new RegExp(token.replace(/[.*+?{}()|[\]\\]/g, '\\$&'), 'i');
            return regex.test(header.transformer.fn(value));
          });
        }

        const { property, operator, value } = match.groups;
        const [attribute, ...subAttributes] = property.split('.');
        const header = headers.find(({ alias }) => attribute === alias);
        if (!header) return false;
        const outValue = String(value).toLocaleLowerCase();
        const rawItem = (() => {
          if (!subAttributes.length) return header.transformer.fn(item[header.key]);
          return subAttributes.reduce((acc, prop) => {
            return acc && Object.hasOwnProperty.call(acc, prop) ? acc[prop] : '';
          }, item[header.key]);
        })();
        if (operator === '=') return outValue === String(rawItem).toLocaleLowerCase();
        if (typeof rawItem === 'number') return eval(`${rawItem} ${operator} ${parseInt(outValue, 10)}`);
        return eval(`"${String(rawItem).toLocaleLowerCase()}" ${operator} "${outValue}"`);
      })();
      stack.push(exists);
    } else {
      const operator = token === 'and' ? '&&' : '||';
      const top = stack.pop();
      const next = stack.pop();
      stack.push(eval(`${top} ${operator} ${next}`));
    }
  }
  return stack.pop();
};

Vue.mixin({
  methods: {
    fromNow: (value) => {
      if (!value) return '(never)';
      const units = {
        year: 1000 * 60 * 60 * 24 * 365,
        month: (1000 * 60 * 60 * 24 * 365) / 12,
        day: 1000 * 60 * 60 * 24,
        hour: 1000 * 60 * 60,
        minute: 1000 * 60,
        second: 1000,
      };

      var rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

      const elapsed = new Date(value * 1000) - new Date();

      for (const key in units) {
        if (Math.abs(elapsed) > units[key] || key === 'second') {
          return rtf.format(Math.round(elapsed / units[key]), key);
        }
      }
    },
    truncate(value, length = 5) {
      if (String(value).length <= length) return value;
      return `${String(value).substring(0, length)}...`;
    },
    getIcon(device) {
      if (device.communication_interface.interface_type === 'ADB') return 'robot';
      if (device.communication_interface.interface_type === 'SSH') return 'terminal';
      if (device.communication_interface.interface_type === 'SSM') return 'network-wired';
      return 'circle';
    },
    evaluate(headers, item, expression) {
      const tokens = expression.trim().split(/\s+(?=(?:[^"]*"[^"]*")*[^"]*$)/g);

      const fixed = fixQuery(tokens.map((value) => value.replace(/"/g, '')));

      const infix = infixToPostfix(fixed);

      return evaluate(headers, item, infix);
    },
  },
});
