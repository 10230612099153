const endpoint = '/devices';

export default {
  state: () => {
    return { devices: [], fetching: false };
  },
  getters: {
    devices(state) {
      return state.devices;
    },
    isFetchingDevices(state) {
      return state.fetching;
    },
  },
  mutations: {
    setDevices(store, devices) {
      store.devices = devices;
    },
  },
  actions: {
    async fetchDevices({ state, commit, getters: { request } }) {
      state.fetching = true;
      const { data } = await request.get(endpoint).catch(() => ({ data: { devices: [] } }));
      state.fetching = false;
      commit('setDevices', data.devices);
    },
    async getDevice({ getters: { request } }, id) {
      const { data } = await request.get(`${endpoint}/${id}`);
      return data.device;
    },
    async fetchGroupDevices({ getters: { request } }, groupId) {
      const params = { group_id: groupId };
      const { data } = await request.get(endpoint, { params }).catch(() => ({ data: { devices: [] } }));
      return data.devices.map((device) => {
        if (!device.capabilities) device.capabilities = [];
        if (device.group_id === 'None') device.group_id = null;
        if (device.in_use_test_id === 'None') device.in_use_test_id = null;
        return device;
      });
    },
  },
};
