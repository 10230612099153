const endpoint = '/hosts';

export default {
  state: () => {
    return { hosts: [], fetching: false };
  },
  getters: {
    hosts(state) {
      return state.hosts;
    },
    isFetchingHosts(state) {
      return state.fetching;
    },
  },
  mutations: {
    setHosts(store, hosts) {
      store.hosts = hosts;
    },
  },
  actions: {
    async fetchHosts({ state, commit, getters: { request } }) {
      state.fetching = true;
      const { data } = await request.get(endpoint).catch(() => ({ data: { hosts: [] } }));
      state.fetching = false;
      commit('setHosts', data.hosts);
    },
    async getHost({ getters: { request } }, id) {
      const { data } = await request.get(`${endpoint}/${id}`);
      return data.host;
    },
    async fetchHostDevices({ getters: { request } }, id) {
      const { data } = await request.get(`${endpoint}/${id}/devices`).catch(() => ({ data: { devices: [] } }));
      const devices = data.devices.map((device) => {
        if (!device.capabilities) device.capabilities = [];
        if (device.group_id === 'None') device.group_id = null;
        if (device.in_use_test_id === 'None') device.in_use_test_id = null;
        return device;
      });
      return devices;
    },
    async createHost({ commit, getters: { request } }, host) {
      const result = await request.post(endpoint, host);
      commit('setHosts', result);
    },
  },
};
