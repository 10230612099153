<template>
  <div id="app" class="d-flex flex-column">
    <CustomNotification />
    <ErrorLog />
    <LayoutHeader class="container-fluid px-3 py-2 bg-primary" />
    <RouterView v-if="$store.getters.user" class="flex-fill bg-white py-3" />
    <SplashView v-else class="vh-100" />
    <LayoutFooter class="py-2 mt-auto" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutHeader from '@/components/Layout/Header.vue';
import LayoutFooter from '@/components/Layout/Footer.vue';
import SplashView from '@/views/Splash.vue';
import CustomNotification from '@/components/Common/Notification.vue';
import ErrorLog from '@/components/Common/ErrorLog.vue';

export default {
  name: 'App',
  components: { LayoutHeader, LayoutFooter, SplashView, CustomNotification, ErrorLog },
  computed: { ...mapGetters(['errors']) },
  methods: {
    infoClass(level) {
      if (level === 'danger') return 'alert-danger';
      if (level === 'success') return 'alert-success';
      if (level === 'warning') return 'alert-warning';
      return 'alert-light';
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  text-align: center;
  min-height: 100vh;
}
</style>
