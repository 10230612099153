<template>
  <div class="container-fluid d-flex flex-column shadow-sm py-2 h-100">
    <h3 class="fs-5">Devices</h3>
    <div class="row panel">
      <div class="col-3 text-primary">
        <RouterLink :to="{ name: 'Devices' }" class="d-inline-block text-decoration-none text-reset">
          <strong class="fs-1 d-block">{{ totalDevices }}</strong>
          <span class="fs-6">Total</span>
        </RouterLink>
      </div>
      <div class="col-3 text-success">
        <RouterLink
          :to="{ name: 'Devices', query: { q: 'status=active & hstatus=healthy' } }"
          class="d-inline-block text-decoration-none text-reset"
        >
          <strong class="fs-1 d-block">{{ healthyDevices }}</strong>
          <span class="fs-6">Healthy</span>
        </RouterLink>
      </div>
      <div class="col-3 text-success">
        <RouterLink
          :to="{ name: 'Devices', query: { q: 'status=active' } }"
          class="d-inline-block text-decoration-none text-reset"
        >
          <strong class="fs-1 d-block">{{ activeDevices }}</strong>
          <span class="fs-6">Active</span>
        </RouterLink>
      </div>
      <div class="col-3 text-secondary">
        <RouterLink
          :to="{ name: 'Devices', query: { q: 'free=no' } }"
          class="d-inline-block text-decoration-none text-reset"
        >
          <strong class="fs-1 d-block">{{ inUseDevices }}</strong>
          <span class="fs-6">In Use</span>
        </RouterLink>
      </div>
      <div class="col-12"><hr class="border-1" /></div>
    </div>
    <div class="d-flex justify-content-around panel">
      <RouterLink
        class="d-inline-block text-decoration-none"
        v-for="(count, key) in freeDevicesByProductId"
        :key="key"
        :to="{ name: 'Devices', query: { q: `${key} and free=yes and status=active and hstatus=healthy` } }"
      >
        <div class="text-info" title="Free, Active, and Healthy">
          <strong class="fs-1">{{ count }}</strong>
          <small>Free</small>
        </div>
        <strong class="fs-6 d-block text-truncate">{{ key }}</strong>
      </RouterLink>
    </div>
    <RouterLink :to="{ name: 'Devices' }" class="pt-3 text-decoration-none mt-auto">
      <FontAwesomeIcon :icon="['fas', 'bars']" fixed-width />
      Device Details
    </RouterLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeviceStatus',
  computed: {
    ...mapGetters(['devices']),
    totalDevices() {
      return this.devices.length;
    },
    activeDevices() {
      return this.devices.filter(({ active }) => active).length;
    },
    healthyDevices() {
      return this.devices.filter(({ active, health_status }) => active && health_status === 'healthy').length;
    },
    inUseDevices() {
      return this.devices.filter(({ in_use_test_id }) => in_use_test_id !== 'None').length;
    },
    freeDevicesByProductId() {
      const devices = this.devices.map((device) => device).sort((a, b) => (a.product_id < b.product_id ? -1 : 1));
      return devices.reduce((acc, device) => {
        if (!acc[device.product_id]) acc[device.product_id] = 0;
        if (device.active && device.health_status === 'healthy' && device.in_use_test_id === 'None') {
          acc[device.product_id] += 1;
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  line-height: 1;
}
</style>
