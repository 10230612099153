<template>
  <header v-if="$store.getters.user">
    <div class="row">
      <div class="col-12 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center justify-content-lg-start">
        <RouterLink :to="{ name: 'Home' }" class="d-flex align-items-center text-decoration-none">
          <img class="logo" src="@/assets/amazon_smile_RGB.png" alt="Amazon Smile" />
          <div class="text-white px-2">
            <h1 class="m-0">{{ appName }}</h1>
            <h2 class="m-0">Test Automation Platform</h2>
          </div>
        </RouterLink>
      </div>
      <div class="col-12 col-lg-2 col-xl-3 my-3 my-lg-0 d-flex justify-content-center align-items-center">
        <CustomDropdown
          @change="updateGroup"
          :label="groupFilter"
          :items="dropdownGroups"
          icon="angles-down"
          class="w-100 border rounded bg-light"
          highlight-class="bg-light"
          width="100%"
          dropdownwidth="100%"
          style="max-width: 20em"
          text-center
        />
      </div>
      <div class="col-12 col-lg-6 col-xl-5 d-flex justify-content-center align-items-center justify-content-lg-end">
        <ul class="nav justify-content-center text-small">
          <li class="px-3">
            <RouterLink
              :to="{ name: 'Dashboard' }"
              class="text-decoration-none"
              :class="isRouteActive('Dashboard') ? 'text-light' : 'text-muted'"
            >
              <FontAwesomeLayers class="me-1">
                <FontAwesomeIcon :icon="['fas', 'gauge']" size="lg" fixed-width />
                <FontAwesomeIcon
                  :icon="['fas', 'circle']"
                  transform="shrink-1 down-5 right-13"
                  class="text-primary"
                  fixed-width
                />
                <FontAwesomeIcon :icon="['fas', 'circle']" transform="shrink-4 down-5 right-13" fixed-width />
                <FontAwesomeIcon
                  :icon="['fas', 'bolt']"
                  transform="shrink-6 down-5 right-13"
                  class="text-primary"
                  fixed-width
                />
              </FontAwesomeLayers>
              <small class="d-block">Dashboard</small>
            </RouterLink>
          </li>
          <li class="px-3">
            <RouterLink
              :to="{ name: 'Hosts' }"
              class="text-decoration-none"
              :class="isRouteActive('Hosts') ? 'text-light' : 'text-muted'"
            >
              <FontAwesomeIcon :icon="['fas', 'computer']" size="lg" class="me-1" fixed-width />
              <small class="d-block">Hosts</small>
            </RouterLink>
          </li>
          <li class="px-3">
            <RouterLink
              :to="{ name: 'Groups' }"
              class="text-decoration-none"
              :class="isRouteActive('Groups') ? 'text-light' : 'text-muted'"
            >
              <FontAwesomeIcon :icon="['fas', 'layer-group']" size="lg" class="me-1" fixed-width />
              <small class="d-block">Groups</small>
            </RouterLink>
          </li>
          <li class="px-3">
            <RouterLink
              :to="{ name: 'Devices' }"
              class="text-decoration-none"
              :class="isRouteActive('Devices') ? 'text-light' : 'text-muted'"
            >
              <FontAwesomeIcon :icon="['fas', 'plug']" transform="rotate-45" size="lg" class="me-1" fixed-width />
              <small class="d-block">Devices</small>
            </RouterLink>
          </li>
          <li class="px-3">
            <RouterLink
              :to="{ name: 'Tests' }"
              class="text-decoration-none"
              :class="isRouteActive('Tests') ? 'text-light' : 'text-muted'"
            >
              <FontAwesomeIcon :icon="['fas', 'list-check']" size="lg" class="me-1" fixed-width />
              <small class="d-block">Tests</small>
            </RouterLink>
          </li>
          <li class="px-3">
            <div
              class="rounded-circle bg-light photo"
              :style="{ backgroundImage: `url(${$store.getters.user.photo})` }"
            ></div>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomDropdown from '@/components/Common/Dropdown.vue';

export default {
  name: 'LayoutHeader',
  components: { CustomDropdown },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['configs', 'groups', 'groupFilter']),
    appName() {
      const { APP_NAME } = this.configs;
      return APP_NAME;
    },
    dropdownGroups() {
      const allGroups = { key: 'All Groups', value: 'All Groups' };
      const groupsMap = this.groups.map(({ group_id }) => ({ key: group_id, value: group_id }));
      return [allGroups, ...groupsMap];
    },
  },
  methods: {
    isRouteActive(name) {
      return new RegExp(`^${name}`).test(this.$route?.name);
    },
    updateGroup(group) {
      if (group === this.groupFilter) return;
      this.$store.commit('setGroupFilter', group);
      this.$store.commit('clearTests');
      this.$store.dispatch('fetchTests');
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 1.5em;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 0.75em;
}

.photo {
  width: 3em;
  height: 3em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
