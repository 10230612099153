<template>
  <div v-if="items.length" class="notification fixed-top px-1 d-flex flex-column ms-auto">
    <div v-for="{ timestamp, level, title, message } in items" :key="timestamp" aria-atomic="true">
      <div
        class="toast show text-start my-1"
        :class="{
          'toast-danger': level === 'danger',
          'toast-success': level === 'success',
          'toast-warning': level === 'warning',
        }"
        role="alert"
        aria-live="assertive"
        delay="1000"
      >
        <div class="toast-header d-flex align-items-center">
          <div class="me-1">
            <FontAwesomeIcon v-if="level === 'danger'" :icon="['fas', 'bug']" fixed-width />
            <FontAwesomeIcon v-else-if="level === 'warning'" :icon="['fas', 'triangle-exclamation']" fixed-width />
            <FontAwesomeIcon v-else-if="level === 'info'" :icon="['fas', 'circle-info']" fixed-width />
            <FontAwesomeIcon v-else :icon="['fas', 'bell']" fixed-width />
          </div>
          <span>{{ title }}</span>
          <button type="button" class="btn-close ms-auto" aria-label="Close" @click="dispose(timestamp)"></button>
        </div>
        <div class="toast-body">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CustomNotification',
  data() {
    return { destroyed: [], pending: [] };
  },
  mounted() {
    this.$nextTick(() => this.notifications.forEach(this.triggerTimeout));
  },
  watch: {
    notifications: {
      deep: true,
      handler(notifications) {
        notifications.forEach(this.triggerTimeout);
      },
    },
  },
  computed: {
    ...mapGetters(['notifications', 'notificationTimeout']),
    items() {
      return this.notifications.filter(({ timestamp }) => !this.destroyed.includes(timestamp));
    },
  },
  methods: {
    ...mapMutations(['archive']),
    triggerTimeout(notification) {
      this.archive(notification);
      if (this.pending.includes(notification.timestamp)) return;
      this.pending.push(notification.timestamp);
      setTimeout(() => this.dispose(notification.timestamp), this.notificationTimeout);
    },
    dispose(timestamp) {
      const destroyed = new Set(this.destroyed);
      destroyed.add(timestamp);
      this.destroyed = Array.from(destroyed);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  z-index: 1500;
  width: fit-content;

  .toast {
    background-color: rgba(var(--bs-light-rgb), 0.95);
    overflow: hidden;

    &.toast-danger {
      background-color: rgba(var(--bs-danger-rgb), 0.95);
      color: var(--bs-dark);
    }

    &.toast-success {
      background-color: rgba(var(--bs-success-rgb), 0.95);
      color: var(--bs-dark);
    }

    &.toast-warning {
      background-color: rgba(var(--bs-warning-rgb), 0.95);
      color: var(--bs-dark);
    }

    .toast-header {
      background-color: rgba(0, 0, 0, 0);
      color: var(--bs-dark);
    }

    .toast-body {
      background-color: rgba(255, 255, 255, 0.85);
    }
  }
}
</style>
