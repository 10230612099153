import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import DashboardView from '@/views/Dashboard.vue';

Vue.use(VueRouter);

let refreshTimer, hostTimer, deviceTimer, testTimer;

/**
 * Re-authorize if the token is about to expire (within 5 minutes).
 *
 * @param { Boolean } force
 */
const refreshToken = async (force = false) => {
  if (!force && refreshTimer) return;
  const { credentials } = store.getters;
  const deltaTime = Math.floor((new Date(credentials.expireTime) - new Date()) / (60 * 1000));
  setTimeout(async () => {
    const user = await store.dispatch('authorize', {}).catch(() => null);
    const { origin, pathname, search } = new URL(location.href);
    localStorage.setItem('destination', pathname + search);
    if (!user) return window.location.replace(redirectUrl(origin));
    refreshToken(true);
  }, (deltaTime - 5) * 60 * 1000);
};

/**
 * Start fetching hosts every 5 minutes.
 *
 * @param { Boolean } force
 */
const fetchHosts = async (force = false) => {
  if (!force && hostTimer) return;
  await store.dispatch('fetchHosts');
  hostTimer = setTimeout(() => fetchHosts(true), 5 * 60 * 1000);
};

/**
 * Start fetching groups every 5 minutes.
 *
 * @param { Boolean } force
 */
const fetchGroups = async (force = false) => {
  if (!force && hostTimer) return;
  await store.dispatch('fetchGroups');
  hostTimer = setTimeout(() => fetchGroups(true), 5 * 60 * 1000);
};

/**
 * Start fetching devices every 1 minutes.
 *
 * @param { Boolean } force
 */
const fetchDevices = async (force = false) => {
  if (!force && deviceTimer) return;
  await store.dispatch('fetchDevices');
  deviceTimer = setTimeout(() => fetchDevices(true), 5 * 60 * 1000);
};

/**
 * Start fetching tests every 30 seconds.
 *
 * @param { Boolean } force
 */
const fetchTests = async (force = false) => {
  if (!force && testTimer) return;
  await store.dispatch('fetchTests');
  testTimer = setTimeout(() => fetchTests(true), 0.5 * 60 * 1000);
};

/**
 * Fetch application wide data
 */
const fetchData = () => {
  fetchHosts();
  fetchGroups();
  fetchDevices();
  fetchTests();
};

/**
 * Fetch test by test ID
 *
 * @param { String } id
 * @returns
 */
const fetchTest = async (id) => {
  const [cached] = store.getters.tests.filter(({ test_id }) => test_id === id);
  if (cached) return cached;
  const test = await store.dispatch('fetchTest', id).catch(() => null);
  return test;
};

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
  },
  {
    path: '/hosts',
    name: 'Hosts',
    component: () => import('@/views/Hosts/ListHosts.vue'),
    children: [
      {
        path: 'create',
        name: 'Hosts.Create',
        component: () => import('@/views/Hosts/CreateHost.vue'),
      },
    ],
  },
  {
    path: '/hosts/:id',
    name: 'Hosts.View',
    component: () => import('@/views/Hosts/ViewHost.vue'),
    async beforeEnter(to, from, next) {
      const host = await store.dispatch('getHost', to.params.id).catch(() => null);
      if (!host) return next({ name: 'Hosts' });
      to.params.host = host;
      next();
    },
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('@/views/Groups/ListGroups.vue'),
  },
  {
    path: '/groups/:id',
    name: 'Groups.View',
    component: () => import('@/views/Groups/ViewGroup.vue'),
    async beforeEnter(to, from, next) {
      const group = await store.dispatch('getGroup', to.params.id).catch(() => null);
      if (!group) return next({ name: 'Groups' });
      to.params.group = group;
      next();
    },
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('@/views/Devices/ListDevices.vue'),
    children: [
      {
        path: '/devices/:id',
        name: 'Devices.View',
        component: () => import('@/views/Devices/ViewDevice.vue'),
        async beforeEnter(to, from, next) {
          const device = await store.dispatch('getDevice', to.params.id).catch(() => null);
          if (!device) return next({ name: 'Devices' });
          to.params.device = device;
          to.params.closeUrl = /Devices/i.test(from.name) ? from : undefined;
          next();
        },
      },
    ],
  },
  {
    path: '/tests',
    name: 'Tests',
    component: () => import('@/views/Tests/ListTests.vue'),
    children: [
      {
        path: ':id',
        name: 'Tests.View',
        component: () => import('@/views/Tests/ViewTest.vue'),
        async beforeEnter(to, from, next) {
          const test = await fetchTest(to.params.id);
          if (!test) return next({ name: 'Tests' });
          if (!test.capabilities) test.capabilities = [];
          if (!test.test_suites) test.test_suites = [];
          to.params.test = test;
          to.params.closeUrl = /Tests/i.test(from.name) ? from : undefined;
          next();
        },
      },
    ],
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('@/views/Reports/ListReports.vue'),
    redirect: { name: 'Tests' },
    children: [
      {
        path: ':id',
        name: 'Reports.View',
        component: () => import('@/views/Reports/ViewReport.vue'),
        async beforeEnter(to, from, next) {
          const test = await store.dispatch('fetchTest', to.params.id).catch(() => null);
          if (!test || test.state !== 'completed') return next({ name: 'Tests' });
          to.params.test = test;
          next();
        },
      },
    ],
  },
  { path: '*', component: () => import('@/views/Splash.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const redirectUrl = (redirectUri) => {
  const { COGNITO_URL, CLIENT_ID, IDENTITY_PROVIDER } = store.getters.configs;
  const url = new URL('/oauth2/authorize', COGNITO_URL);
  url.searchParams.append('client_id', CLIENT_ID);
  url.searchParams.append('redirect_uri', redirectUri);
  url.searchParams.append('identity_provider', IDENTITY_PROVIDER);
  url.searchParams.append('response_type', 'code');
  return url.toString();
};

router.beforeEach(async (to, from, next) => {
  // Cache the destination route
  const { origin } = new URL(location.href);
  const { code } = to.query;

  // Authorize user to access the dashboard
  const user = await store.dispatch('authorize', { origin, code }).catch(() => null);

  if (!user) {
    if (!code) localStorage.setItem('destination', to.fullPath);
    // Get new authorization key if error is not present in the url query
    if (!to.query.error) return window.location.replace(redirectUrl(origin));
    return next();
  }

  Promise.all([fetchData(), refreshToken()]);

  const destination = localStorage.getItem('destination');
  localStorage.removeItem('destination');

  return code ? next({ path: destination }) : next();
});

router.afterEach((to) => {
  const { APP_NAME } = store.getters.configs;
  // Dynamically update the title of the page, i.e. <title>{{ title }}</title>
  const title = to.matched.reduce((init, { meta: { title: t } }) => (t ? t : init), null);
  Vue.nextTick(() => (document.title = `${APP_NAME} ${title ? ' - ' + title : ''}`));
});

export default router;
