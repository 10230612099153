import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import App from '@/App.vue';
import '@/plugins/directives';
import '@/plugins/filters';
import '@/plugins/mixins';
import '@/plugins/fontawesome';
import '@/assets/styles.scss';

const handleResponse = (response) => response;

const handleFailure = (error) => {
  store.commit('errors', error);
  return Promise.reject(error);
};

axios.interceptors.response.use(handleResponse, handleFailure);

Vue.config.productionTip = false;

new Vue({ router, store, render: (h) => h(App) }).$mount('#app');
