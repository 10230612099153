import axios from 'axios';
import awsV4 from 'aws-v4';
import Cloudwatch from 'aws-sdk/clients/cloudwatch';
import CloudwatchLogs from 'aws-sdk/clients/cloudwatchlogs';
import S3 from 'aws-sdk/clients/s3';

/**
 * Handles the request fulfillment state
 *
 * @param   { Object } client
 * @param   { Object } config
 * @returns { Object }
 */
const onFulfilled = (client, config) => {
  const { method, body, url: path, params: queryParams = {} } = config;
  const { endpoint, pathComponent, signRequest } = client;
  const contentType = config.headers['Content-Type'];
  const { headers } = signRequest({ method, path, queryParams, body, headers: { 'Content-Type': contentType } });

  for (const key in headers) config.headers[key] = headers[key];

  config.url = endpoint + pathComponent + path;

  return config;
};

/**
 * Handles the request rejection state
 *
 * @param   { Object          } error
 * @returns { Promise<Object> }
 */
const onRejected = (error) => Promise.reject(error);

/**
 * Get configuration variable for each production stage. Default values are considered
 * to be alpha stage values.
 *
 * @returns { Object }
 */
const getConfigs = (stage) => {
  const COGNITO_URL = `https://ihm-tap-${stage}.auth.us-west-2.amazoncognito.com`;
  const API_URL = `https://${stage}.api.tap.jihm.amazon.dev`;
  const IDENTITY_PROVIDER = 'FederateOIDC';

  const configs = { COGNITO_URL, API_URL, IDENTITY_PROVIDER };

  configs.CLIENT_ID = '6rtl67c1sk8os91erja5c45oo8';
  configs.IDENTITY_POOL_ID = 'us-west-2:ccc8c24d-fb79-4034-b365-1645f0000af6';
  configs.USER_POOL_ID = 'us-west-2_GaYataupO';

  if (stage === 'beta') {
    configs.CLIENT_ID = '2qu14nus737frkgvlmqf9j6100';
    configs.IDENTITY_POOL_ID = 'us-west-2:51574cbd-91ac-406a-935a-7d0c9c4b7259';
    configs.USER_POOL_ID = 'us-west-2_VRu2SXf01';
  }

  if (stage === 'gamma') {
    configs.CLIENT_ID = 'cq4622fmtonasmpja9iigjab6';
    configs.IDENTITY_POOL_ID = 'us-west-2:aca76116-b7c2-4695-a939-de6de0297d81';
    configs.USER_POOL_ID = 'us-west-2_sfjuNnlEs';
  }

  if (stage === 'epsilon') {
    configs.CLIENT_ID = '4qac7l7csnts36h0q28tmku4rn';
    configs.IDENTITY_POOL_ID = 'us-west-2:defcc3cf-70c5-4fb7-b8ad-1e6e7bf04df6';
    configs.USER_POOL_ID = 'us-west-2_fUS1iUEMe';
  }

  if (stage === 'prod') {
    configs.API_URL = 'https://api.tap.jihm.amazon.dev';
    configs.CLIENT_ID = 'on058e8gbhodd3vte6illspd3';
    configs.IDENTITY_POOL_ID = 'us-west-2:eb2f352c-a43f-4b07-b115-dbc9fd971692';
    configs.USER_POOL_ID = 'us-west-2_X1jktNgzL';
  }

  return configs;
};

export default {
  state: () => {
    const { VUE_APP_STAGE } = process.env;
    const configs = getConfigs(VUE_APP_STAGE);

    const TEST_JOBS_BUCKET = `${VUE_APP_STAGE}-tap-test-jobs`;
    const REGION = (configs.USER_POOL_ID || '').replace(/_.*$/, '');
    const CONTENT_TYPE = 'application/json';
    const APP_NAME = 'OSPlat TAP';
    Object.assign(configs, { TEST_JOBS_BUCKET, REGION, CONTENT_TYPE, APP_NAME });

    return { configs: Object.freeze(configs), errors: [], notifications: [], notificationTimeout: 10 * 1000 };
  },
  getters: {
    configs(state) {
      return state.configs;
    },
    errors(state) {
      return state.errors;
    },
    notificationTimeout(state) {
      return state.notificationTimeout;
    },
    notifications(state, getters) {
      const errors = getters.errors.reduce((prev, error) => {
        const { archivedAt, message: title, response, timestamp, type, code } = error;
        if (archivedAt <= Date.now()) return prev;
        const message = (() => {
          if (!response) return "That's all we know";
          const { data } = response;
          return data?.errorMessage || `(${code}) That's all we know`;
        })();
        return [...prev, { message, level: 'danger', title, timestamp, type }];
      }, []);
      return [...state.notifications, ...errors].sort((a, b) => b.timestamp - a.timestamp);
    },
    request(state, rootState) {
      const { REGION: region, CONTENT_TYPE: contentType, API_URL: endpoint } = state.configs;
      const { accessKeyId: accessKey, secretAccessKey: secretKey, sessionToken } = rootState.credentials;
      const client = awsV4.newClient({ accessKey, secretKey, sessionToken, region, endpoint });

      const request = axios.create({ headers: { 'Content-Type': contentType } });

      const [handler] = axios.interceptors.response.handlers;
      if (handler) request.interceptors.response.use(handler.fulfilled, handler.rejected);
      request.interceptors.request.use((config) => onFulfilled(client, config), onRejected);

      return request;
    },
    logs(state, rootState) {
      const { REGION: region } = state.configs;
      const { accessKeyId, secretAccessKey, sessionToken } = rootState.credentials;
      return new CloudwatchLogs({ region, credentials: { accessKeyId, secretAccessKey, sessionToken } });
    },
    cloudwatch(state, rootState) {
      const { REGION: region } = state.configs;
      const { accessKeyId, secretAccessKey, sessionToken } = rootState.credentials;
      return new Cloudwatch({ region, credentials: { accessKeyId, secretAccessKey, sessionToken } });
    },
    s3(state, rootState) {
      const { REGION: region } = state.configs;
      const { accessKeyId, secretAccessKey, sessionToken } = rootState.credentials;
      return new S3({ region, credentials: { accessKeyId, secretAccessKey, sessionToken } });
    },
  },
  mutations: {
    errors(state, error) {
      state.errors.push({ ...error, type: 'error', timestamp: Date.now() });
    },
    notifications(state, notification) {
      state.notifications.push({ ...notification, type: 'notification', timestamp: Date.now() });
    },
    archive(state, notification) {
      if (notification.type === 'notification') {
        const index = state.notifications.findIndex(({ timestamp }) => timestamp === notification.timestamp);
        if (index >= 0) state.notifications[index].archivedAt = Date.now() + state.notificationTimeout;
        return;
      }
      const index = state.errors.findIndex(({ timestamp }) => timestamp === notification.timestamp);
      if (index >= 0) state.errors[index].archivedAt = Date.now() + state.notificationTimeout;
    },
  },
};
