import Vue from 'vue';

import '../assets/styles.scss';

import {
  faAnglesDown,
  faAnglesLeft,
  faAnglesRight,
  faAnglesUp,
  faArrowTrendUp,
  faArrowUpRightFromSquare,
  faAt,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBug,
  faCheck,
  faCircle,
  faCloudArrowDown,
  faCodeMerge,
  faCompass,
  faComputer,
  faEllipsis,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faFolderOpen,
  faGauge,
  faGear,
  faHandshakeSlash,
  faInfo,
  faInfoCircle,
  faLayerGroup,
  faLightbulb,
  faLink,
  faLinkSlash,
  faListCheck,
  faMagnifyingGlass,
  faMapLocationDot,
  faNetworkWired,
  faPlug,
  faPlusCircle,
  faRobot,
  faSquare,
  faSortDown,
  faSortUp,
  faTerminal,
  faTimes,
  faWineGlassEmpty,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faAnglesDown,
  faAnglesLeft,
  faAnglesRight,
  faAnglesUp,
  faArrowTrendUp,
  faArrowUpRightFromSquare,
  faAt,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBug,
  faCheck,
  faCircle,
  faCloudArrowDown,
  faCodeMerge,
  faCompass,
  faComputer,
  faEllipsis,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faFolderOpen,
  faGauge,
  faGear,
  faInfo,
  faHandshakeSlash,
  faInfoCircle,
  faLayerGroup,
  faLightbulb,
  faLink,
  faLinkSlash,
  faListCheck,
  faMagnifyingGlass,
  faMapLocationDot,
  faNetworkWired,
  faPlug,
  faPlusCircle,
  faRobot,
  faSquare,
  faSortDown,
  faSortUp,
  faTerminal,
  faTimes,
  faWineGlassEmpty
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
